<template>
    <validation-observer ref="observer" rules="required" v-slot="{ handleSubmit }">
        <div class="h-full flex flex-col justify-between items-start gap-32">
            <div class="flex flex-col justify-start gap-4">
                <div class="border-b border-dashed border-romanSilver pb-4">
                    <div class="flex flex-col justify-between items-start gap-6 bg-ghostWhite p-4 rounded">
                        <h3 class="font-bold text-lg text-jet">This is a one-time setting.</h3>
                        <p class="font-normal text-base text-jet">
                            If you are setting up on Cloudenly for the first time, the leave balances
                            settings allow you to account for individual employee annual leave status
                            so that the system can learn how to treat each employee's annual leave requests.
                        </p>
                    </div>
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <c-card class="w-full flex flex-col p-5 pt-0">
                        <CardFooter
                            reloadcard
                            @reload="getOneOffLeaveBalances"
                            @actionModal="onFilter"
                            @sortType="$queryBuilder({ sort: $event }, getOneOffLeaveBalances)"
                            @searchResult="$queryBuilder({ search: $event }, getOneOffLeaveBalances)"
                        />
                    </c-card>
                    <c-table
                        :headers="headers"
                        :items="leaveBalanceItems"
                        aria-label="one off leave balances table"
                        v-if="isFetching || leaveBalanceItems.length"
                        :loading="isFetching"
                        :has-number="true"
                        page-sync
                        :pagination-list="pagination"
                        @page="handleSelectedPage($event)"
                        @itemsPerPage="$queryBuilder({ perPage: $event }, getOneOffLeaveBalances)"
                    >
                        <template v-slot:item="{ item }">
                            <div v-if="item.userId" class="flex justify-start items-start gap-2">
                                <div>
                                    <span v-if="item.photo" class="flex justify-center items-center w-9 h-9">
                                        <img :src="item.data.photo" class="w-9 h-9 rounded" alt="profile photo" />
                                    </span>
                                    <div v-else class="flex justify-center items-center w-9 h-9 rounded border">
                                        <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                                            {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                                        </span>
                                    </div>
                                </div>
                                <span class="font-normal text-darkPurple text-sm">
                                    {{ item.data.fname }} {{ item.data.lname }}
                                </span>
                            </div>
                            <span v-if="item.userLevel" class="text-darkPurple text-sm font-normal">
                                {{ item.data.userLevel && item.data.userLevel.name }}
                            </span>
                            <span v-if="item.leaveEntitlementDays" class="text-darkPurple text-sm font-normal">
                                {{ item.data.leaveEntitlementDays }}
                            </span>
                            <span v-if="item.leaveBalance" class="text-darkPurple text-sm font-normal">
                                <div v-if="settingStatus === 'completed'">
                                    <div class="h-10 flex items-center px-5 bg-cultured border border-romanSilver rounded">
                                        <p class="text-base text-darkPurple">{{ item.data.leaveBalance }}</p>
                                    </div>
                                </div>
                                <validation-provider v-else class="w-full" :name="`${item.data.leaveBalance}-${item.index}`" :rules="{ leaveBalance: { item: item.data } }" v-slot="{ errors }">
                                    <c-text type="number" min="0" placeholder="Enter Balance" v-model="item.data.leaveBalance" class="-mt-2 -pt-2" />
                                    <small class="text-desire leading-0">{{ errors[0] }}</small>
                                </validation-provider>
                            </span>
                            <span v-if="item.id" class="text-darkPurple text-sm font-normal">
                                <div v-if="settingStatus === 'completed'">
                                    <div class="h-10 flex items-center px-5 bg-cultured border border-romanSilver rounded">
                                        <p class="text-base text-darkPurple">{{ item.data.status }}</p>
                                    </div>
                                </div>
                                <c-select v-else :options="leaveAllowanceOptions" v-model="item.data.status" />
                            </span>
                        </template>
                    </c-table>
                    <div v-else class="w-full flex flex-col justify-center items-center gap-5 px-10 pb-20">
                        <Icon size="" class-name="w-80 h-80" icon-name="payinsight_Illustration" />
                        <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
                            A list of one-off leave balances for your organisaton will be shown here.
                            Once added you can filter and perform necessary actions.
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="leaveBalanceItems.length" class="relative bottom-10 w-full bg-white flex justify-between">
                <div class="w-full bottom-0 p-0 flex justify-start gap-2">
                    <c-button
                        label="Save"
                        :disabled="isFetching || settingStatus === 'completed'"
                        :loading="isFetching"
                        variant="primary btn-bg button-style hover:btn-bg hover:opacity-90"
                        @onClick="isOpenSubmitBalance = true"
                    />
                    <c-button
                        label="Cancel"
                        :disabled="isFetching || settingStatus === 'completed'"
                        variant="bg-transparent button-style hover:bg-cultured"
                        @onClick="$router.back()"
                    />
                </div>
                <c-button
                    label="Save as Draft"
                    :disabled="isFetching || settingStatus === 'completed'"
                    :loading="isFetching"
                    variant="secondary button-style btn-border hover:bg-flame hover:opacity-90 whitespace-nowrap"
                    @onClick="handleSubmit(onSubmit('draft'))"
                />
            </div>

            <Modal v-if="isOpenSubmitBalance">
                <c-card class="p-5 flex flex-col">
                <div class="border-b border-romanSilver">
                    <p class="text-jet font-bold text-base">Action Required!</p>
                </div>
                <div class="flex flex-col justify-start gap-2">
                    <div class="flex flex-col justify-start items-start gap-1 my-5 w-96">
                        <p class="font-bold text-base text-jet">Note:
                            <span class="font-semibold sentence">
                                This is a one-time setting, you will not be able to update
                                it again once it is saved. You can save it as a draft if
                                you want to come back and continue later.
                            </span>
                        </p>
                    </div>
                    <p class="font-bold">Do you wish to continue?</p>
                    <div class="flex justify-end items-center gap-2">
                    <c-button
                        class="button-class btn-border button-class-secondary"
                        label="Cancel"
                        @onClick="isOpenSubmitBalance = false"
                    />
                    <c-button
                        class="btn-bg button-class button-class-pimary"
                        label="Continue"
                        :disabled="isFetching || settingStatus === 'completed'"
                        :loading="isFetching"
                        @onClick="handleSubmit(onSubmit())"
                    />
                    </div>
                </div>
                </c-card>
            </Modal>
            <Modal v-if="isOpenSelectedPage">
                <c-card class="p-5 flex flex-col">
                <div class="border-b border-romanSilver">
                    <p class="text-jet font-bold text-base">Action Required!</p>
                </div>
                <div class="flex flex-col justify-start gap-2">
                    <div class="flex flex-col justify-start items-start gap-1 my-5 w-96">
                        <p class="font-bold text-base text-jet">Warning:
                            <span class="font-semibold sentence">
                                All unsaved changes will be lost if you navigate away from this page.
                                To retain your changes, kindly <strong>Save As Draft</strong>
                                before navigating to the next register items.
                            </span>
                        </p>
                    </div>
                    <p class="font-bold">Do you wish to continue?</p>
                    <div class="flex justify-end items-center gap-2">
                    <c-button
                        class="button-class btn-border button-class-secondary"
                        label="Cancel"
                        @onClick="isOpenSelectedPage = false"
                    />
                    <c-button
                        class="btn-bg button-class button-class-pimary"
                        label="Continue"
                        @onClick="handleSelectedPage(selectedPage)"
                    />
                    </div>
                </div>
                </c-card>
            </Modal>

            <FilterTable
                v-if="isOpenFilter"
                :filter-data="filterOptions"
                @close="isOpenFilter = false"
                @submit="isOpenFilter = false"
            />
        </div>
    </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CText from "@scelloo/cloudenly-ui/src/components/text"
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CardFooter from "@/components/CardFooter"
import Icon from "@/components/Icon"
import FilterTable from "@/components/FilterTable"
import Modal from "@/components/Modal";

extend('leaveBalance', {
    params: ['item'],
    message: 'Annual leave balance cannot be more than total annual leave entitlement',
    validate: (value, { item }) => (
        item.leaveBalance <= item.leaveEntitlementDays && item.leaveBalance >= 0
    ),
})

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        CTable,
        CCard,
        CText,
        CSelect,
        CButton,
        CardFooter,
        FilterTable,
        Icon,
        Modal
    },
    data() {
        return {
            tested: {},
            isFetching: false,
            isOpenFilter: false,
            isOpenSubmitBalance: false,
            isOpenSelectedPage: false,
            filterOptions: [],
            leaveBalanceItems: [],
            pagination: {},
            activeItem: {},
            settingStatus: null,
            selectedPage: null,
            headers: [
                { title: "Employee Name", value: "userId" },
                { title: "Job Level", value: "userLevel" },
                { title: "TOTAL ANNUAL LEAVE ENTITLEMENT (DAYS)", value: "leaveEntitlementDays" },
                { title: "ANNUAL LEAVE BALANCE (DAYS)", value: "leaveBalance" },
                { title: "Leave Allowance Status", value: "id" },
            ],
            leaveAllowanceOptions: [
                'Paid',
                'Processed for Payment',
                'Not Requested'
            ],
        }
    },
    methods: {
        onFilter(){
            this.filterOptions = [
                { header: 'Location(s)', optionsBox: this.locationOptions },
                { header: 'Function(s)', optionsBox: this.functionOptions },
                { header: 'Level(s)', optionsBox: this.levelOptions },
                { header: 'Designation(s)', optionsBox: this.designationOptions },
            ]
            this.isOpenFilter = true
        },
        handleSelectedPage(page) {
            if (!this.isOpenSelectedPage) {
                this.selectedPage = page
                this.isOpenSelectedPage = true
                return
            }
            this.$queryBuilder({ page }, this.getOneOffLeaveBalances)
            this.isOpenSelectedPage = false
        },
        getOneOffLeaveBalances(queryParams){
            this.isFetching = true
            this.$_getOneOffLeaveBalances(queryParams).then(({ data }) => {
                this.leaveBalanceItems = data.data
                this.pagination = data.meta
                this.settingStatus = data.status
                this.isFetching = false
            }).catch((error) => {
                this.$toasted.error(error.message, { duration: 3000 })
                this.isFetching = false
            })
        },
        async onSubmit(submitStatus) {
            this.$refs.observer.validate().then((isValidated) => {
                if (isValidated) {
                    this.isFetching = true
                    this.isOpenSubmitBalance = false

                    const payload = {
                        status: submitStatus,
                        employees: this.leaveBalanceItems.map(item => ({
                            ...item, daysLeft: item.leaveBalance
                        }))
                    }

                    this.$_submitOneOffLeaveBalances(payload).then(({ data }) => {
                        this.$toasted.success(data.message, {duration: 3000})
                        this.getOneOffLeaveBalances()
                    }).catch((error) => {
                        const message = 'Something went wrong, Try again'
                        this.$toasted.error(error.message || message, {duration: 3000})
                        this.isFetching = false
                    })
                }
            })
        }
    },
    mounted() {
        this.getOneOffLeaveBalances()
    }
}
</script>

<style scoped>
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}

.dropdownMenu {
   margin-right: 30px !important;
 }
 .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
 .button-style {
    font-weight: 600 !important;
    font-size: 16px !important;
    font-size: 16px !important;
    line-height: 120% !important;
    padding: 10px 40px !important;
 }
 .button_class {
    padding: 10px 40px !important;
    font-weight: 600;
    display: flex;
    justify-self: end;
  }
  .button-class-pimary {
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
  }
  .button-class-secondary {
    color: #321c3b !important;
    background: white !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
  }
  .alert-style {
    position: relative;
    right: 0; top: -5px;
    box-shadow: none;
    background: #FFFFFF;
    color: #E99323;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid rgb(254 202 202);
  }

.btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>